
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import useTemplatesOpenModal from '@/compositions/templates/useTemplatesOpenModal'
import type { TemplatesCategory } from '@/definitions/templates/types'

export default defineComponent({
  components: {
    TmDropdown,
    TmDropdownItem,
    TmTableActionButton,
  },
  props: {
    item: {
      type: Object as PropType<TemplatesCategory>,
    },
  },
  setup() {
    const {
      openModalEditTemplate,
      openModalShareTemplates,
      openModalDeleteTemplate,
      openModalDuplicateTemplate,
    } = useTemplatesOpenModal()

    return {
      openModalEditTemplate,
      openModalDuplicateTemplate,
      openModalShareTemplates,
      openModalDeleteTemplate,
    }
  },
})
