
import { defineComponent } from 'vue'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import TmButton from '@/components/shared/TmButton.vue'
import useTemplatesOpenModal from '@/compositions/templates/useTemplatesOpenModal'

export default defineComponent({
  components: { TmButton, TmEmptyState },
  setup() {
    const { openModalTemplate } = useTemplatesOpenModal()

    return {
      openModalTemplate,
    }
  },
})
