
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import useTemplatesOpenModal from '@/compositions/templates/useTemplatesOpenModal'
import type { TemplatesCategory } from '@/definitions/templates/types'
import { copyToClipboard } from '@/services/utils'
import TopFilterButtonCopy from '@/components/layout/topFilter/TopFilterButtonCopy.vue'

export default defineComponent({
  components: {
    TopFilterButtonCopy,
    TopFilterButton,
    TopFilter,
  },
  props: {
    selected: {
      type: Array as PropType<TemplatesCategory[]>,
    },
  },
  setup(props) {
    const {
      openModalEditCategory,
      openModalTemplate,
      openModalEditTemplate,
      openModalShareTemplates,
      openModalDeleteTemplate,
    } = useTemplatesOpenModal()
    const textToCopy = computed(() => (props.selected || []).reduce((acc, el: TemplatesCategory, i) => {
      if (!i) acc += el.content
      else acc += ` ${el.content}`

      return acc
    }, ''))

    return {
      copyToClipboard,
      openModalEditCategory,
      openModalTemplate,
      openModalEditTemplate,
      openModalShareTemplates,
      openModalDeleteTemplate,
      textToCopy,
    }
  },
})
