
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import { capitalize } from '@/services/utils'
import TmTemplate from '@/components/shared/TmTemplate.vue'
import CampaignsTemplatesDropdown from '@/components/pages/campaigns/templates/CampaignsTemplatesDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import useTemplatesOpenModal from '@/compositions/templates/useTemplatesOpenModal'

export default defineComponent({
  components: { TmButton, CampaignsTemplatesDropdown, TmTemplate, TmBadge, TmTable },
  setup() {
    const {
      openModalEditTemplate,
    } = useTemplatesOpenModal()

    return {
      capitalize,
      openModalEditTemplate,
    }
  },
})
