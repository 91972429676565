import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter_button = _resolveComponent("top-filter-button")!
  const _component_top_filter_button_copy = _resolveComponent("top-filter-button-copy")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, {
    selected: _ctx.selected,
    "search-placeholder": "Search templates"
  }, {
    "filter-line-left-default": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "New template",
        icon: "add",
        onClick: _ctx.openModalTemplate
      }, null, 8, ["onClick"])
    ]),
    "filter-line-left-selected": _withCtx(() => [
      (_ctx.selected.length < 2)
        ? (_openBlock(), _createBlock(_component_top_filter_button, {
            key: 0,
            text: "Send SMS",
            icon: "email",
            to: { name: 'base.compose.message' }
          }, null, 8, ["to"]))
        : _createCommentVNode("", true),
      _createVNode(_component_top_filter_button, {
        text: "Edit category",
        icon: "edit",
        onClick: _ctx.openModalEditCategory
      }, null, 8, ["onClick"]),
      _createVNode(_component_top_filter_button_copy, { text: _ctx.textToCopy }, null, 8, ["text"]),
      _createVNode(_component_top_filter_button, {
        text: "Share",
        icon: "tmi-people",
        onClick: _ctx.openModalShareTemplates
      }, null, 8, ["onClick"]),
      _createVNode(_component_top_filter_button, {
        text: "Delete",
        icon: "delete",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModalDeleteTemplate(_ctx.selected)))
      })
    ]),
    _: 1
  }, 8, ["selected"]))
}