
import { computed, defineComponent, ref, toRefs } from 'vue'
import { useModes } from '@/compositions/modes'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { formatDate } from '@/services/dateTimeService'
import { getTableData } from '@/services/tableService'
import { filterItemsByValues } from '@/services/utils'
import type { TemplatesCategory } from '@/definitions/templates/types'
import { useBreakpoints } from '@/compositions/breakpoints'
import CampaignsTemplatesFilter from '@/components/pages/campaigns/templates/CampaignsTemplatesFilter.vue'
import CampaignsTemplatesTable from '@/components/pages/campaigns/templates/CampaignsTemplatesTable.vue'
import CampaignsTemplatesEmpty from '@/components/pages/campaigns/templates/CampaignsTemplatesEmpty.vue'

export default defineComponent({
  components: {
    CampaignsTemplatesFilter,
    CampaignsTemplatesEmpty,
    CampaignsTemplatesTable,
    PageContent,
  },
  props: {
    mockName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { isMdMax } = useBreakpoints()
    const { mockName } = toRefs(props)
    const { isEmptyMode } = useModes()
    const search = ref('')
    const expand = ref(false)
    const selected = ref()
    const defaultHeaders = ref<TableHeaders[]>([
      { text: 'Name', value: 'name-slot' },
      { text: 'Category', value: 'category-slot' },
      { text: 'Content', value: 'content-slot', width: '45%', minWidth: '300px', expandPossible: true },
      { text: 'Last modified', value: 'last-modified-slot', format: (val: string) => formatDate(val) },
    ])
    const sendHeader = ref<TableHeaders>({ text: 'Action', value: 'send-slot', width: '86px', hideSortable: true, cellClass: 'templates-send-cell' })
    const tableHeaders = computed<TableHeaders[]>(() => isMdMax.value ? defaultHeaders.value : [...defaultHeaders.value, sendHeader.value])
    const tableItems = computed<TemplatesCategory[]>(() => getTableData(mockName.value))
    const filteredTableItems = computed<TemplatesCategory[]>(
      () => tableItems.value.filter((item) => filterItemsByValues(search.value, [item.name, item.content, item.category]))
    )

    return {
      search,
      expand,
      isEmptyMode,
      selected,
      tableHeaders,
      filteredTableItems,
    }
  },
})
