
import { defineComponent, ref } from 'vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import { copyToClipboard } from '@/services/utils'

export default defineComponent({
  components: {
    TopFilterButton,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Copy text',
    },
    icon: {
      type: String,
      default: 'tmi-duplicate',
    },
    tooltip: {
      type: String,
      default: 'Copied',
    },
  },
  setup(props) {
    const showTooltip = ref(false)
    const onCopy = () => {
      showTooltip.value = true
      copyToClipboard(props.text)
      setTimeout(() => {
        showTooltip.value = false
      }, 1500)
    }

    return {
      showTooltip,
      onCopy,
    }
  },
})
