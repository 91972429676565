import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter_button = _resolveComponent("top-filter-button")!

  return (_openBlock(), _createBlock(_component_top_filter_button, {
    text: _ctx.label,
    icon: _ctx.icon,
    tooltip: _ctx.tooltip,
    "show-tooltip": _ctx.showTooltip,
    "manual-show-tooltip": "",
    onClick: _ctx.onCopy
  }, null, 8, ["text", "icon", "tooltip", "show-tooltip", "onClick"]))
}