import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_campaigns_templates_filter = _resolveComponent("campaigns-templates-filter")!
  const _component_campaigns_templates_table = _resolveComponent("campaigns-templates-table")!
  const _component_campaigns_templates_empty = _resolveComponent("campaigns-templates-empty")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, null, {
    "filter-bar-dropdown": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-dropdown")
    ]),
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    default: _withCtx(() => [
      (!_ctx.isEmptyMode)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_campaigns_templates_filter, {
              search: _ctx.search,
              "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              selected: _ctx.selected
            }, null, 8, ["search", "selected"]),
            _createVNode(_component_campaigns_templates_table, {
              modelValue: _ctx.selected,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected) = $event)),
              headers: _ctx.tableHeaders,
              items: _ctx.filteredTableItems,
              "item-key": "id",
              "items-name": "templates",
              "show-selection": "",
              "hide-phantom-col": ""
            }, null, 8, ["modelValue", "headers", "items"])
          ], 64))
        : (_openBlock(), _createBlock(_component_campaigns_templates_empty, { key: 1 }))
    ]),
    _: 3
  }))
}