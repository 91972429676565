import { useModals } from '@/compositions/modals'
import type { TemplatesCategory } from '@/definitions/templates/types'
import { templatesCategoryOptions } from '@/definitions/_general/_data/optionsList'

type UseTemplatesOpenModal = () => {
  openModalCategory: () => void
  openModalRenameCategory: (categoryName: string) => void
  openModalEditCategory: () => void
  openModalDeleteCategory: (categoryName: string) => void
  openModalTemplate: () => void
  openModalEditTemplate: (item: TemplatesCategory) => void
  openModalShareTemplates: () => void
  openModalShareConfirm: () => void
  openModalDuplicateTemplate: () => void
  openModalDeleteTemplate: (items: TemplatesCategory[]) => void
}

const useTemplatesOpenModal: UseTemplatesOpenModal = () => {
  const { openModal } = useModals()

  const openModalCategory = () => {
    openModal('singleInput', {
      modalTitle: 'New category',
      label: 'Category name',
      placeholder: 'Enter category name',
    })
  }

  const openModalRenameCategory = (categoryName: string) => {
    openModal('singleInput', {
      modalTitle: 'Rename category',
      label: 'Category name',
      placeholder: 'Enter category name',
      value: categoryName,
    })
  }

  const openModalEditCategory = () => {
    openModal('singleSelect', {
      modalTitle: 'Edit category',
      description: [
        { text: 'The selected ' },
        { text: '10 templates', style: 'semi-bold' },
        { text: ' will be moved to a new category.' },
      ],
      label: 'Move templates to this category',
      options: templatesCategoryOptions,
    })
  }

  const openModalDeleteCategory = (categoryName: string) => {
    openModal('templatesDeleteCategory', {
      modalTitle: 'Delete category',
      categoryName,
    })
  }

  const openModalTemplate = () => {
    openModal('templatesTemplate', {
      modalTitle: 'New template',
    })
  }

  const openModalEditTemplate = (item: TemplatesCategory) => {
    openModal('templatesTemplate', {
      btnText: 'Save',
      modalTitle: 'Edit template',
      name: item.name,
      content: item.content,
      category: item.category,
    })
  }

  const openModalShareTemplates = () => {
    openModal('templatesShare', {
      modalTitle: 'Share templates with Sub-accounts',
    })
  }

  const openModalShareConfirm = () => {
    openModal('confirmation', {
      title: 'Share with sub-accounts',
      text: 'The selected templates will be copied to the selected sub-accounts. This action cannot be undone. Are you sure you would like to share the selected templates with the sub-accounts?',
      btnText: 'Share templates',
    })
  }

  const openModalDuplicateTemplate = () => {
    openModal('confirmation', {
      title: 'Duplicate template',
      text: 'Are you sure you would like to duplicate this template?',
      btnText: 'Duplicate',
    })
  }

  const openModalDeleteTemplate = (items: TemplatesCategory[]) => {
    let text
    if (items.length > 1) {
      text = 'Are you sure you would like to delete selected templates?'
    } else {
      text = [
        { text: 'Are you sure you would like to delete ', style: '' },
        { text: items[0].name, style: 'semi-bold' },
        { text: ' template?', style: '' },
      ]
    }
    openModal('confirmation', {
      title: 'Delete template',
      text,
      btnText: 'Delete',
      btnColor: 'red',
    })
  }

  return {
    openModalCategory,
    openModalRenameCategory,
    openModalEditCategory,
    openModalDuplicateTemplate,
    openModalDeleteCategory,
    openModalTemplate,
    openModalEditTemplate,
    openModalShareTemplates,
    openModalShareConfirm,
    openModalDeleteTemplate,
  }
}

export default useTemplatesOpenModal
